* {
  margin: 0;
  padding: 0;
}

html,
body {
  min-height: 100%;
  min-width: 350px;
  overflow: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  /* min-width: 350px; */
  height: 100%;
}

*::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #21c493;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
